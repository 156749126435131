const testString = 'This is a test!111';

const fruits = [
  'mango',
  'papaya',
  'lychee',
];

fruits.map((fruit) => {
  console.log(fruit);
});

